import React from 'react'

import { Helmet } from 'react-helmet'

import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Order Submission</title>
        <meta property="og:title" content="Order Submission" />
      </Helmet>
      <iframe
        src="https://sockfancy.retool.com/embedded/public/7d93ee7d-e41b-42cb-b966-03181442476e"
        allowFullScreen
        className="home-iframe"
      ></iframe>
    </div>
  )
}

export default Home
